// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import CommandActions from "../../../redux/CommandActions";

class Menu extends Component {
  render() {
    const { executeAbout, executeContact } = this.props;
    return (
      <table id="menu">
        <tbody>
          <tr>
            <td className="command-link color-define" onClick={executeAbout}>
              about
            </td>
            <td>&nbsp;&nbsp;- </td>
            <td>About me</td>
          </tr>
          <tr>
            <td className="command-link color-define" onClick={executeContact}>
              contact
            </td>
            <td>&nbsp;&nbsp;- </td>
            <td>Contat details (twitter, linkedin, email, etc)</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  executeAbout: () => dispatch(CommandActions.execute("about")),
  executeContact: () => dispatch(CommandActions.execute("contact"))
});

export default connect(null, mapDispatchToProps)(Menu);
