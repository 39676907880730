// @flow

import React, { Component } from "react";

import "./CommandNotFound.css";

class CommandNotFound extends Component {
  render() {
    const { command } = this.props;

    return (
      <div id="input" className="color-error-msg">
        <span className="shell-name">tidbeck:</span>
        <span className="color-error-msg"> {command}: command not found</span>
      </div>
    );
  }
}

export default CommandNotFound;
