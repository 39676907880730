// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import "./Contact.css";

class Contact extends Component {
  render() {
    return (
      <table id="contact">
        <tbody>
          <tr>
            <td>email</td>
            <td>&nbsp;&nbsp;- </td>
            <td>
              <a className="color-macro" href="mailto:anders.tidbeck@gmail.com">
                anders.tidbeck@gmail.com
              </a>
            </td>
          </tr>
          <tr>
            <td>twitter</td>
            <td>&nbsp;&nbsp;- </td>
            <td className="color-macro">
              <a
                className="color-macro"
                href="https://twitter.com/tidbeck"
                target="_blank"
                rel="noopener noreferrer"
              >
                @tidbeck
              </a>
            </td>
          </tr>
          <tr>
            <td>linkedin</td>
            <td>&nbsp;&nbsp;- </td>
            <td>
              <a
                className="color-macro"
                href="https://www.linkedin.com/in/tidbeck"
                target="_blank"
                rel="noopener noreferrer"
              >
                tidbeck
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export default connect()(Contact);
