// @flow

import { v4 as uuid } from "uuid";

const Command = {
  Types: {
    EXECUTE: "COMMAND_EXECUTE",
    CLEAR_HISTORY: "COMMAND_CLEAR_HISTORY"
  },
  execute: command => ({
    type: Command.Types.EXECUTE,
    key: uuid(),
    command
  }),
  clearHistory: command => ({
    type: Command.Types.CLEAR_HISTORY
  })
};

export default Command;
