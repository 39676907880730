import React, { Component } from "react";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";

import CommandReducer from "./redux/CommandReducer";
import Screen from "./components/screen/Screen";

import "./App.css";

const reducers = combineReducers({
  command: CommandReducer
});

const store = createStore(reducers);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Screen />
      </Provider>
    );
  }
}

export default App;
