// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import Welcome from "../welcome/Welcome";
import Input from "../input/Input";
import EmptyLine from "../empty-line/EmptyLine";
import CommandExecuted from "../command-executed/CommandExecuted";
import CommandNotFound from "../command-not-found/CommandNotFound";
import Menu from "../commands/menu/Menu";
import About from "../commands/about/About";
import Contact from "../commands/contact/Contact";

import "./Screen.css";

class Screen extends Component {
  scrollToBottom = () => {
    if (this.input !== undefined) {
      console.log(this.input);
      this.input.scrollIntoView({ behavior: "instant" });
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
  componentFromHistoryEntry(entry) {
    switch (entry.command) {
      case "menu":
        return <Menu />;
      case "about":
        return <About />;
      case "contact":
        return <Contact />;
      default:
        return <CommandNotFound command={entry.command} />;
    }
  }

  render() {
    const { history } = this.props;

    const commandsExecuted = history.map(entry => {
      return (
        <div key={entry.key}>
          <CommandExecuted command={entry.command} />
          {this.componentFromHistoryEntry(entry)}
          <EmptyLine />
        </div>
      );
    });
    return (
      <div id="screen" className="color-background">
        <div
          ref={el => {
            this.input = el;
          }}
        />
        <Input />
        {commandsExecuted.reverse()}
        <Welcome />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  history: state.command.history
});

export default connect(mapStateToProps)(Screen);
