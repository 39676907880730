// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import avatar from "../../../assets/images/southpark_512.png";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div id="about">
        <img className="avatar" src={avatar} alt="Avatar" />
        <h2>Intro</h2>
        <ul>
          <li>Name: Anders Tidbeck</li>
          <li>Occupation: Software developer</li>
        </ul>
        <h2>Summary</h2>
        <p>Software developer for 10 years</p>
      </div>
    );
  }
}

export default connect()(About);
