// @flow

import React, { Component } from "react";

import "./CommandExecuted.css";

class CommandExecuted extends Component {
  render() {
    const { command } = this.props;

    return (
      <div id="input">
        <span className="shell-name">tidbeck-v0.1$</span>
        <span>{command}</span>
      </div>
    );
  }
}

export default CommandExecuted;
