// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import CommandActions from "../../redux/CommandActions";

import "./Input.css";

class Input extends Component {
  state = {
    command: ""
  };

  componentWillMount() {
    document.addEventListener("keydown", this.onKeyPressed.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPressed.bind(this));
  }

  onKeyPressed(e) {
    const { key, ctrlKey } = e;
    if (ctrlKey) {
      if (/^l$/.test(key)) {
        const { clearHistory } = this.props;
        clearHistory();
      }
    } else {
      if (/^[a-zA-Z ]$/.test(key)) {
        this.appendChar(key.toLowerCase());
      } else if (/Backspace/.test(key)) {
        this.removeChar();
      } else if (/Enter/.test(key)) {
        this.executeCommand();
      }
    }
  }

  appendChar(char) {
    const command = this.state.command + char;
    this.setState({ command });
  }

  removeChar() {
    const command = this.state.command.slice(0, -1);
    this.setState({ command });
  }

  executeCommand() {
    const { command } = this.state;
    const { execute } = this.props;
    this.setState({ command: "" });
    execute(command);
  }

  render() {
    const { command } = this.state;

    return (
      <div id="input">
        <span className="shell-name">tidbeck-v0.1$</span>
        <span>{command}</span>
        <span className="cursor">&#9608;</span>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  execute: command => dispatch(CommandActions.execute(command)),
  clearHistory: command => dispatch(CommandActions.clearHistory())
});

export default connect(null, mapDispatchToProps)(Input);
