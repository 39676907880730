// @flow

import React, { Component } from "react";
import { connect } from "react-redux";

import CommandActions from "../../redux/CommandActions";
import EmptyLine from "../empty-line/EmptyLine";

import "./Welcome.css";

class Welcome extends Component {
  render() {
    const { executeMenu } = this.props;
    return (
      <div id="welcome">
        <pre className="ascii-art color-constant">{`
 _______  ___   ______   _______  _______  _______  ___   _        _______  _______ 
|       ||   | |      | |  _    ||       ||       ||   | | |      |       ||       |
|_     _||   | |  _    || |_|   ||    ___||       ||   |_| |      |  _____||    ___|
  |   |  |   | | | |   ||       ||   |___ |       ||      _|      | |_____ |   |___ 
  |   |  |   | | |_|   ||  _   | |    ___||      _||     |_  ___  |_____  ||    ___|
  |   |  |   | |       || |_|   ||   |___ |     |_ |    _  ||   |  _____| ||   |___ 
  |___|  |___| |______| |_______||_______||_______||___| |_||___| |_______||_______|
        `}</pre>
        <EmptyLine />
        <div className="message">
          Welcome to the personal website of{" "}
          <span className="color-identifier">Anders Tidbeck</span>
        </div>
        <div>
          Type or click '
          <span className="command-link color-define" onClick={executeMenu}>
            menu
          </span>' to get started
        </div>
        <EmptyLine />
        <EmptyLine />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  executeMenu: () => dispatch(CommandActions.execute("menu"))
});

export default connect(null, mapDispatchToProps)(Welcome);
