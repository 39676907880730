// @flow

import CommandActions from "./CommandActions";

const initalState = {
  history: []
};

const Command = (state = initalState, action) => {
  console.log(state);
  const { Types } = CommandActions;
  switch (action.type) {
    case Types.EXECUTE:
      return {
        ...state,
        history: [
          ...state.history,
          { key: action.key, command: action.command }
        ]
      };
    case Types.CLEAR_HISTORY:
      return {
        ...state,
        history: []
      };
    default:
      return state;
  }
};

export default Command;
